import React from "react"

import styles from "./FeatureAccessWrapper.module.css"

import PlanButton from "~/common/buttons/PlanButton"

import { usePermissions } from "~/Permissions/usePermissions"
import { Card } from "~/dashboard"

import CardGradientBorderWrapper from "./CardGradientBorderWrapper"
import TrialPlanButton from "./TrialPlanButton"
import {
  ChargebeeUIFeatureId,
  features,
  getPlansForFeature,
} from "./plansAndFeatures"
import { useEntitlementQuantity, useEntitlementSwitch } from "./useEntitlements"

type Props = {
  featureId: ChargebeeUIFeatureId
  children: React.ReactNode
}

const FeatureAccessWrapper = ({ featureId, children }: Props) => {
  const { can, subject } = usePermissions()
  const hasFeatureSwitch = useEntitlementSwitch(featureId)
  const hasFeatureQuantity = useEntitlementQuantity(featureId)

  const isAdmin = can("manage", subject("Account"))
  const feature = features[featureId]

  if (hasFeatureSwitch || hasFeatureQuantity > 0 || !feature) {
    return <>{children}</>
  }

  const availablePlans = getPlansForFeature({ featureId })
  const subText = isAdmin
    ? "Change your plan today or talk to our team about a plan that best suits your needs"
    : `Talk to your admin to upgrade`

  return (
    <>
      <div className={styles.bg} data-test="feature-access-wrapper" />
      <Card className={styles.card}>
        <CardGradientBorderWrapper>
          <h1 className={styles.title}>Ready to use {feature.name}?</h1>
          <p className={styles.plans}>
            {feature.name} are part of our {availablePlans} plans. {subText}.
          </p>
        </CardGradientBorderWrapper>
        <div className={styles.buttons}>
          {isAdmin && <TrialPlanButton />}
          <PlanButton href="/account/billing" style="outline" />
        </div>
      </Card>

      {children}
    </>
  )
}

export default FeatureAccessWrapper
